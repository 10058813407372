<template>
    <v-card flat class="mb-14">
        <v-card-title>
            <span class="secondary--text font-weight-bold">Add New Application</span>
        </v-card-title>
        <div v-if="applicationFamilies.length && !fetchingApplications">
            <v-card-text>
                <v-autocomplete
                    solo
                    multiple
                    chips
                    clearable
                    deletable-chips
                    v-model="selectedTags"
                    :items="tagList"
                    label="Type here to search"
                    @update:search-input="searchTermUpdated"
                    ref="tagSearch"></v-autocomplete>
                <div class="mb-6">
                    <v-chip-group show-arrows multiple>
                        <v-chip
                            v-for="(tag, index) in tagList"
                            :key="`tag-${index}`"
                            label
                            class="mr-2 my-1"
                            @click="tagChipClicked(tag)"
                            :color="selectedTags.includes(tag) ? 'primary' : undefined">
                            {{ tag }}
                        </v-chip>
                    </v-chip-group>
                </div>
                <v-tabs centered v-model="selectedTab">
                    <v-tab v-for="(group, i) in applicationFamilyGroups" :key="`group-${i}`">
                        <div class="d-flex flex-column">
                            <div>
                                <strong>{{ group.name }}</strong>
                                ({{ group.apps.length }})
                            </div>
                        </div>
                    </v-tab>
                    <v-tab-item v-for="(applicationFamilies, index) in applicationFamilyGroups" :key="`tab-${index}`">
                        <v-container fluid>
                            <v-row v-if="applicationFamilyGroups.length > 1">
                                <v-col cols="12" class="mb-1" sm="6" md="4" lg="3" xl="2" v-for="(app, index) in filteredFamilies" :key="`app-${index}`">
                                    <v-tooltip :close-delay="3" v-if="app.disabled_reason != null" bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <v-card
                                                    flat
                                                    color="grey lighten-3"
                                                    @click="selectedApp = app"
                                                    :disabled="app.disabled_reason != null"
                                                    style="filter: grayscale(100%)">
                                                    <v-list-item class="py-2">
                                                        <v-list-item-avatar tile>
                                                            <img :src="app.icon_url" />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="mr-1 text-truncate secondary--text header-font">
                                                                {{ app.name }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card>
                                            </div>
                                        </template>
                                        <span v-if="appDisabledReasons[app.disabled_reason]">{{ appDisabledReasons[app.disabled_reason] }}</span>
                                        <span v-else>This application is not available</span>
                                    </v-tooltip>
                                    <v-card
                                        v-else
                                        flat
                                        :color="`${selectedApp === app ? 'grey' : 'white'} lighten-3`"
                                        @click="selectAndScroll(app)"
                                        :elevation="selectedApp === app ? 0 : 4">
                                        <v-list-item class="py-2">
                                            <v-list-item-avatar tile>
                                                <img :src="app.icon_url" />
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title class="mr-1 text-truncate secondary--text header-font">
                                                    {{ app.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="mb-1" sm="6" md="4" lg="3" xl="2" v-if="familiesToDisplay <= filteredFamilies.length">
                                    <v-card flat @click="displayAllFamilies = !displayAllFamilies">
                                        <v-list-item class="py-2">
                                            <v-list-item-avatar tile>
                                                <v-icon>{{ displayAllFamilies ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title class="mr-1 text-truncate secondary--text header-font">
                                                    <span>{{ displayAllFamilies ? 'Display less' : 'Display all' }}</span>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-else justify="center">
                                <v-col cols="12" sm="6" class="text-center">
                                    <v-alert :value="true" type="info" prominent class="mt-3" text>
                                        <div class="d-flex flex-column">
                                            <span class="font-weight-bold">No applications found.</span>
                                            Try removing or changing some tags to get more results.
                                        </div>
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs>
                <div ref="appDetails">
                    <v-fade-transition>
                        <v-container fluid v-if="selectedApp">
                            <v-row>
                                <v-col cols="12" sm="4" lg="3" xl="2">
                                    <v-card class="mt-13">
                                        <v-card-title>
                                            {{ selectedApp?.name }}
                                        </v-card-title>
                                        <v-card-text>
                                            <v-img width="50%" class="ma-auto" :src="selectedApp?.icon_url" contain></v-img>
                                        </v-card-text>
                                        <v-card-text>
                                            <viewer
                                                id="appDescription"
                                                ref="appDescription"
                                                :options="defaultOptions"
                                                :initialValue="selectedApp?.description" />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="8" lg="9" xl="10">
                                    <v-row no-gutters>
                                        <v-col cols="8" lg="7" xl="8"><div class="pa-4">Version</div></v-col>
                                        <v-col cols="4" lg="3" xl="2"><div class="pa-4">Release date</div></v-col>
                                        <v-col class="d-none d-lg-flex justify-end" lg="2" xl="2"><div class="pa-4">Actions</div></v-col>
                                    </v-row>
                                    <v-data-iterator
                                        :items="filteredApps"
                                        :items-per-page.sync="itemsPerPage"
                                        no-data-text="No application selected"
                                        :hide-default-footer="addingApp || appAdded">
                                        <template v-slot:default="{ items }">
                                            <v-row>
                                                <v-col v-for="item in items" :key="item.imid" cols="12">
                                                    <v-tooltip :close-delay="3" v-if="item.disabled_reason != null" bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on">
                                                                <v-card elevation="1" :disabled="item.disabled_reason != null">
                                                                    <v-row no-gutters>
                                                                        <v-col cols="8" lg="7" xl="8">
                                                                            <v-list-item two-line>
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title class="mr-1 text-truncate secondary--text header-font">
                                                                                        {{ item.name }}
                                                                                    </v-list-item-title>
                                                                                    <v-list-item-subtitle>
                                                                                        <span class="mr-3">{{ item.short_description }}</span>
                                                                                    </v-list-item-subtitle>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="2" class="d-flex align-center">
                                                                            <div class="px-4">
                                                                                {{ item.release_date | dateToHuman }}
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col cols="12" lg="2" class="d-flex align-center justify-end pa-4">
                                                                            <v-btn
                                                                                color="primary"
                                                                                @click.stop="createAppVersion(item)"
                                                                                :disabled="item.disabled_reason != null"
                                                                                :loading="addingApp">
                                                                                <v-icon small>add</v-icon>
                                                                                Install
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card>
                                                            </div>
                                                        </template>
                                                        <span v-if="appDisabledReasons[item.disabled_reason]">
                                                            {{ appDisabledReasons[item.disabled_reason] }}
                                                        </span>
                                                        <span v-else>This application is not available</span>
                                                    </v-tooltip>
                                                    <version-description-viewer
                                                        :name="selectedVersion?.name"
                                                        :iconUrl="selectedApp?.icon_url"
                                                        :descriptionUrl="selectedVersion?.description"
                                                        :disabled="addingApp || appAdded"
                                                        :versionTags="selectedVersion?.tags"
                                                        :selectedTags="selectedTags"
                                                        v-else>
                                                        <v-card elevation="6" @click="selectAppVersion(item)" :disabled="addingApp || appAdded">
                                                            <v-row no-gutters>
                                                                <v-col cols="8" lg="7" xl="8">
                                                                    <v-list-item two-line>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title class="mr-1 text-truncate secondary--text header-font">
                                                                                {{ item.name }}
                                                                            </v-list-item-title>
                                                                            <v-list-item-subtitle>
                                                                                <span class="mr-3">{{ item.short_description }}</span>
                                                                            </v-list-item-subtitle>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-col>
                                                                <v-col cols="4" lg="3" xl="2" class="d-flex align-center">
                                                                    <div class="px-4">
                                                                        {{ item.release_date | dateToHuman }}
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12" lg="2" class="d-flex align-center justify-end pa-4">
                                                                    <v-btn
                                                                        :color="isVersionAdded(item) ? 'success' : 'primary'"
                                                                        @click.stop="createAppVersion(item)"
                                                                        :loading="isCurrentVersion(item) && addingApp">
                                                                        <template v-if="isVersionAdded(item)">
                                                                            <v-icon small>check</v-icon>
                                                                        </template>
                                                                        <template v-else>
                                                                            <v-icon small>add</v-icon>
                                                                            Install
                                                                        </template>
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </version-description-viewer>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-data-iterator>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-fade-transition>
                </div>
            </v-card-text>
            <v-row>
                <v-spacer />
                <v-btn v-if="createSpaceAndApp.operationSelected" @click="skipAppCreation" :disabled="loading" outlined text color="primary" exact class="ma-6">
                    skip this step
                </v-btn>
            </v-row>
        </div>
        <div v-else-if="fetchingApplications" class="applications-fetching">
            <v-row>
                <v-col cols="12">
                    <v-skeleton-loader type="list-item" class="mx-auto"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" class="d-flex mb-8">
                    <div v-for="i in 6" :key="i" class="px-4">
                        <v-skeleton-loader type="chip"></v-skeleton-loader>
                    </div>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                    <div v-for="i in 6" :key="i" class="px-4">
                        <v-skeleton-loader type="button"></v-skeleton-loader>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="n in filteredApps.length" :key="n">
                    <v-skeleton-loader type="list-item-avatar" class="mx-auto"></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <div v-else-if="!fetchingApplications && !applicationFamilies.length" class="empty-applications-warning">
            <v-alert :value="true" type="warning" prominent class="mt-3" text>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">No applications available.</span>
                    Try refreshing the page, and if the problem persists contact the space administrator.
                </div>
            </v-alert>
        </div>
        <v-dialog v-model="createDialog" width="40%" persistent>
            <v-card :disabled="appAdded">
                <v-card-title>Pick a name for your application</v-card-title>
                <v-card-subtitle class="caption">Application name already exists: {{ appNameTaken }}</v-card-subtitle>
                <v-card-text>
                    <v-form v-model="appDescriptionIsValid" @submit.prevent="createApp()">
                        <v-text-field v-model="appName" required :rules="[rules.nonEmpty]" @focus="selectAll" outlined>
                            <template v-slot:label>Type a name here</template>
                        </v-text-field>
                    </v-form>
                    <v-alert type="warning" text icon="mdi-alert-circle-outline" v-if="errorContent">
                        <span>{{ errorContent }}</span>
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="createDialog = false" :disabled="addingApp" v-if="!appAdded">Cancel</v-btn>
                    <v-btn v-if="appAdded" icon><v-icon color="success">check</v-icon></v-btn>
                    <v-btn v-else text color="primary" @click="createApp()" :disabled="!appDescriptionIsValid" :loading="addingApp">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { remove, find, sortBy, debounce } from 'lodash'
import { Viewer } from '@toast-ui/vue-editor'
import { toastEditor } from '@/mixins/toastEditor'
import { analyticsMixin } from '@/mixins/analytics'

const VersionDescriptionViewer = () => import('../components/VersionDescriptionViewer.vue')

export default {
    name: 'AppStore',
    components: { Viewer, VersionDescriptionViewer },
    mixins: [appTypeAndImageLink, toastEditor, analyticsMixin],
    data() {
        return {
            selectedTab: 0,
            selectedTags: [],
            selectedApp: null,
            selectedVersion: null,
            itemsPerPage: 5,
            createDialog: false,
            appName: '',
            appNameTaken: '',
            appDescription: '',
            appDescriptionIsValid: false,
            rules: {
                nonEmpty: p => p.length > 0 || 'Required field.'
            },
            addingApp: false,
            appAdded: false,
            errorContent: '',
            familiesToDisplay: 7,
            displayAllFamilies: false,
            appStoreEnteredTime: 0
        }
    },
    created() {
        this.$store.dispatch('spaceStore/fetchApplications', this.$route.params.sid)
        this.searchTermUpdated = debounce(searchTerm => {
            if (!searchTerm) return
            this.sendAnalyticsEvent('app store text-based tag search', {
                searchTerm,
                selectedTags: this.selectedTags,
                searchResults: this.$refs.tagSearch.filteredItems
            })
        }, 700)
        this.appStoreEnteredTime = Date.now()
    },
    computed: {
        applicationFamilyGroupNames() {
            const groups = ['All']
            this.familiesWithVersionsWithTags.forEach(family => {
                family.groups.forEach(group => {
                    if (!groups.includes(group)) {
                        groups.push(group)
                    }
                })
            })
            return groups
        },
        applicationFamilyGroups() {
            const groups = []
            this.applicationFamilyGroupNames.forEach(name => {
                groups.push({ name, count: 0, apps: [] })
            })
            this.familiesWithVersionsWithTags.forEach(family => {
                groups[0].apps.push(family)
                family.groups.forEach(group => {
                    const g = find(groups, { name: group })
                    g.apps.push(family)
                })
            })
            return groups
        },
        tagList() {
            if (!this.applicationTags) return []
            const sortedTagEntries = sortBy(Object.entries(this.applicationTags), entry => entry[1].length).reverse()
            const sortedTags = Object.fromEntries(sortedTagEntries)
            return Object.keys(sortedTags)
        },
        versionsWithTags() {
            if (this.selectedTags.length === 0) return this.applicationVersions
            const filteredVersionsSet = this.applicationVersions.reduce((allVersions, thisVersion) => {
                let tagIndex = 0
                let hasAllTags = true
                while (tagIndex < this.selectedTags.length) {
                    const tag = this.selectedTags[tagIndex]
                    if (!this.applicationTags[tag].includes(thisVersion.imid)) hasAllTags = false
                    tagIndex++
                }
                if (hasAllTags) allVersions.add(thisVersion)
                return allVersions
            }, new Set())
            return [...filteredVersionsSet]
        },
        familiesWithVersionsWithTags() {
            const familyIDSet = this.versionsWithTags.reduce((allFamilies, thisFamily) => {
                return allFamilies.add(thisFamily.ifid)
            }, new Set())
            return this.applicationFamilies.filter(family => {
                return familyIDSet.has(family.ifid)
            })
        },
        filteredApps() {
            const versionList = this.selectedTags.length ? this.versionsWithTags : this.applicationVersions
            if (!this.selectedApp) return []
            return versionList.filter(version => this.selectedApp.ifid === version.ifid)
        },
        filteredFamilies() {
            if (this.displayAllFamilies) return this.applicationFamilyGroups[this.selectedTab].apps

            return this.applicationFamilyGroups[this.selectedTab].apps.filter((_, index) => {
                return index < this.familiesToDisplay
            })
        },
        ...mapState('spaceStore', ['fetchingApplications', 'applicationFamilies', 'applicationVersions']),
        ...mapGetters('spaceStore', ['applicationTags']),
        ...mapState('homeStore', ['createSpaceAndApp'])
    },
    methods: {
        tagChipClicked(tag) {
            if (this.selectedTags.includes(tag)) {
                // Need a shallow copy for vue reactivity
                const temp = [...this.selectedTags]
                remove(temp, t => t === tag)
                this.selectedTags = [...temp]
            } else {
                this.selectedTags.push(tag)
            }
            this.sendAnalyticsEvent('app store tag-based search', {
                selectedTags: this.selectedTags,
                displayedFamilies: this.filteredFamilies.map(family => family.name)
            })
        },
        async selectAppVersion(version) {
            this.selectedVersion = version
        },
        createAppVersion(version) {
            this.selectedVersion = version
            this.appName = this.selectedVersion.name
            this.createApp()
        },
        createApp() {
            this.addingApp = true
            this.errorContent = ''
            const apiURL = '/snapshots/' + this.$route.params.snid + '/applications'
            const postBody = {
                long_id: this.appName,
                short_id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(),
                imid: this.selectedVersion.imid,
                pars: '{}',
                description: this.selectedVersion.short_description
            }
            this.$axios
                .post(apiURL, postBody, { timeout: 30000 })
                .then(({ data }) => {
                    this.addingApp = false
                    this.$store.dispatch('appStore/fetchAllUserApps', { iid: this.$route.params.iid, startFirstApp: false })
                    this.appAdded = true
                    this.sendAnalyticsEvent('app store new app created', {
                        aid: data.aid,
                        imid: postBody.imid,
                        selectedTags: this.selectedTags,
                        userTimeSpent: Math.floor((Date.now() - this.appStoreEnteredTime) / 1000) + 's'
                    })
                    this.appStoreEnteredTime = Date.now()
                    setTimeout(() => {
                        this.$router.push({
                            name: 'snapshot-applications',
                            params: this.$route.params
                        })
                        this.createDialog = false
                    }, 2000)
                })
                .catch(error => {
                    const reasons = error?.response?.data?.reason
                    const duplicateName = Array.isArray(reasons) && reasons[0].includes('UniqueViolation')
                    if (!this.createDialog && duplicateName) {
                        this.appNameTaken = this.appName
                        this.appName = ''
                        this.createDialog = true
                        this.addingApp = false
                        return
                    }
                    if (this.createDialog && duplicateName) {
                        this.errorContent = 'Another application uses the name you chose for this application, please choose another name'
                    } else {
                        this.createDialog = false
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: error.description || error.message,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    }
                    this.addingApp = false
                })
        },
        selectAll(e) {
            e.target.select()
        },
        selectAndScroll(app) {
            this.selectedApp = app
            this.$vuetify.goTo(this.$refs.appDetails, {
                duration: 500,
                offset: 0,
                easing: 'easeInOutCubic'
            })
        },
        isCurrentVersion(version) {
            return this.selectedVersion?.imid === version.imid
        },
        isVersionAdded(version) {
            return this.isCurrentVersion(version) && this.appAdded
        },
        skipAppCreation: function () {
            this.$store.dispatch('homeStore/updateCreateSpaceAndApp', { stepVal: 3, skipAppCreationVal: true })
            this.$router.push({
                name: 'snapshot-add-files-hint',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid
                }
            })
        }
    },
    watch: {
        selectedTags() {
            this.selectedTab = 0
            if (this.selectedApp && this.familiesWithVersionsWithTags.filter(family => family.ifid === this.selectedApp.ifid).length === 0) {
                this.selectedApp = null
            }
        },
        selectedApp(app) {
            this.$refs.appDescription.invoke('setMarkdown', app.description)
        },
        displayAllFamilies(to) {
            if (to) return
            if (
                this.selectedApp &&
                this.filteredFamilies.filter(family => {
                    return family.ifid === this.selectedApp.ifid
                }).length === 0
            ) {
                this.selectedApp = null
            }
        }
    },
    beforeDestroy() {
        this.$store.dispatch('homeStore/resetCreateSpaceAndApp')
    }
}
</script>
